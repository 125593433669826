export const schema = {
  definitions: {
    PiqAstropaycardGoDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      required: ['service'],
    },
    PiqAstropaycardNbDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      required: ['service'],
    },
    PiqAstropaycardPhDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      required: ['service'],
    },
    PiqAstropaycardTmDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      required: ['service'],
    },
    PiqAstropaycardUiDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      required: ['service'],
    },
    PiqApcoDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
    },
    PwclickPwclickDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
    },
    // TODO: Bring back 'BIC' when services are implemented
    PiqBankibanDeposit: {
      type: 'object',
      properties: {
        iban: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['iban'],
      },
    },
    PiqBankibanOnlineueberweisenDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['service'],
      },
    },
    PiqCashlibDeposit: {
      type: 'object',
      properties: {
        voucherNumber: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['voucherNumber'],
      },
    },
    PiqCreditcardDeposit: {
      type: 'object',
      properties: {
        cardHolder: {
          type: 'string',
          minLength: 1,
        },
        encCreditcardNumber: {
          type: 'string',
          pattern: '^[0-9]+$',
          format: 'creditCard',
        },
        encCvv: {
          type: 'string',
          pattern: '^[0-9]+$',
        },
        expiryMonth: {
          type: 'string',
          format: 'expiryMonth',
          maxLength: 2,
          minLength: 2,
          maximum: 12,
          minimum: 1,
        },
        expiryYear: {
          type: 'string',
          format: 'expiryYear',
          maxLength: 4,
          minLength: 4,
        },
      },
      required: ['encCvv'],
      allOf: [
        {
          if: {
            properties: {accountId: {const: ''}},
          },
          then: {
            allOf: [
              {
                if: {
                  properties: {encCreditcardNumber: {pattern: '^[1-6]'}},
                },
                then: {
                  properties: {
                    encCreditcardNumber: {
                      minLength: 12,
                      maxLength: 19,
                    },
                  },
                },
                else: {
                  properties: {
                    encCreditcardNumber: {
                      minLength: 16,
                      maxLength: 16,
                    },
                  },
                },
              },
              {
                if: {
                  properties: {encCreditcardNumber: {pattern: '^(34|37)'}},
                },
                then: {
                  properties: {
                    encCvv: {
                      minLength: 4,
                      maxLength: 4,
                    },
                  },
                },
                else: {
                  properties: {
                    encCvv: {
                      minLength: 3,
                      maxLength: 3,
                    },
                  },
                },
              },
            ],
            required: [
              'cardHolder',
              'encCreditcardNumber',
              'expiryMonth',
              'expiryYear',
              'provider',
            ],
          },
          else: {
            properties: {
              encCvv: {
                minLength: 3,
                maxLength: 4,
              },
            },
          },
        },
      ],
    },
    PiqCreditcardMastercardDeposit: {
      type: 'object',
      properties: {
        cardHolder: {
          type: 'string',
          minLength: 1,
        },
        encCreditcardNumber: {
          type: 'string',
          pattern: '^[0-9]+$',
          format: 'creditCard',
        },
        encCvv: {
          type: 'string',
          pattern: '^[0-9]+$',
        },
        expiryMonth: {
          type: 'string',
          format: 'expiryMonth',
          maxLength: 2,
          minLength: 2,
          maximum: 12,
          minimum: 1,
        },
        expiryYear: {
          type: 'string',
          format: 'expiryYear',
          maxLength: 4,
          minLength: 4,
        },
        service: {type: 'string'},
      },
      required: ['encCvv', 'service'],
      allOf: [
        {
          if: {
            properties: {accountId: {const: ''}},
          },
          then: {
            required: [
              'cardHolder',
              'encCreditcardNumber',
              'expiryMonth',
              'expiryYear',
              'provider',
              'service',
            ],
          },
        },
        {
          if: {
            properties: {encCreditcardNumber: {pattern: '^[1-6]'}},
          },
          then: {
            properties: {
              encCreditcardNumber: {
                minLength: 12,
                maxLength: 19,
              },
            },
          },
          else: {
            properties: {
              encCreditcardNumber: {
                minLength: 16,
                maxLength: 16,
              },
            },
          },
        },
        {
          if: {
            properties: {encCreditcardNumber: {pattern: '^(34|37)'}},
          },
          then: {
            properties: {
              encCvv: {
                minLength: 4,
                maxLength: 4,
              },
            },
          },
          else: {
            properties: {
              encCvv: {
                minLength: 3,
                maxLength: 3,
              },
            },
          },
        },
      ],
    },
    PiqCreditcardRupayDeposit: {
      type: 'object',
      properties: {
        cardHolder: {
          type: 'string',
          minLength: 1,
        },
        encCreditcardNumber: {
          type: 'string',
          pattern: '^[0-9]+$',
          format: 'creditCard',
        },
        encCvv: {
          type: 'string',
          pattern: '^[0-9]+$',
        },
        expiryMonth: {
          type: 'string',
          format: 'expiryMonth',
          maxLength: 2,
          minLength: 2,
          maximum: 12,
          minimum: 1,
        },
        expiryYear: {
          type: 'string',
          format: 'expiryYear',
          maxLength: 4,
          minLength: 4,
        },
        service: {type: 'string'},
      },
      required: ['encCvv', 'service'],
      allOf: [
        {
          if: {
            properties: {accountId: {const: ''}},
          },
          then: {
            required: [
              'cardHolder',
              'encCreditcardNumber',
              'expiryMonth',
              'expiryYear',
              'provider',
              'service',
            ],
          },
        },
        {
          if: {
            properties: {encCreditcardNumber: {pattern: '^[1-6]'}},
          },
          then: {
            properties: {
              encCreditcardNumber: {
                minLength: 12,
                maxLength: 19,
              },
            },
          },
          else: {
            properties: {
              encCreditcardNumber: {
                minLength: 16,
                maxLength: 16,
              },
            },
          },
        },
        {
          if: {
            properties: {encCreditcardNumber: {pattern: '^(34|37)'}},
          },
          then: {
            properties: {
              encCvv: {
                minLength: 4,
                maxLength: 4,
              },
            },
          },
          else: {
            properties: {
              encCvv: {
                minLength: 3,
                maxLength: 3,
              },
            },
          },
        },
      ],
    },
    PiqCreditcardVisaDeposit: {
      type: 'object',
      properties: {
        cardHolder: {
          type: 'string',
          minLength: 1,
        },
        encCreditcardNumber: {
          type: 'string',
          pattern: '^[0-9]+$',
          format: 'creditCard',
        },
        encCvv: {
          type: 'string',
          pattern: '^[0-9]+$',
        },
        expiryMonth: {
          type: 'string',
          format: 'expiryMonth',
          maxLength: 2,
          minLength: 2,
          maximum: 12,
          minimum: 1,
        },
        expiryYear: {
          type: 'string',
          format: 'expiryYear',
          maxLength: 4,
          minLength: 4,
        },
        service: {type: 'string'},
      },
      required: ['encCvv', 'service'],
      allOf: [
        {
          if: {
            properties: {accountId: {const: ''}},
          },
          then: {
            required: [
              'cardHolder',
              'encCreditcardNumber',
              'expiryMonth',
              'expiryYear',
              'provider',
              'service',
            ],
          },
        },
        {
          if: {
            properties: {encCreditcardNumber: {pattern: '^[1-6]'}},
          },
          then: {
            properties: {
              encCreditcardNumber: {
                minLength: 12,
                maxLength: 19,
              },
            },
          },
          else: {
            properties: {
              encCreditcardNumber: {
                minLength: 16,
                maxLength: 16,
              },
            },
          },
        },
        {
          if: {
            properties: {encCreditcardNumber: {pattern: '^(34|37)'}},
          },
          then: {
            properties: {
              encCvv: {
                minLength: 4,
                maxLength: 4,
              },
            },
          },
          else: {
            properties: {
              encCvv: {
                minLength: 3,
                maxLength: 3,
              },
            },
          },
        },
      ],
    },
    PiqTrustlyDeposit: {type: 'object'},
    PiqIdealDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
          enum: [
            'ABN_AMRO',
            'ASN',
            'BUNQ',
            'FRIESLAND',
            'ING',
            'KNAB',
            'RABOBANK',
            'SNS',
            'SNS_REGIO',
            'TRIODOS',
            'VAN_LANSCHOT',
          ],
        },
      },
    },
    PiqIDebitDeposit: {type: 'object'},
    PiqIwalletDeposit: {type: 'object'},
    PiqKluwpDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
          enum: [
            'CREDITCARD',
            'CHINA_UNION_PAY',
            'MASTERCARD_GIFT_CARD',
            'POST24',
            'PAYPAL',
            'POSTEPAY',
            'MULTIBANCO',
            'PAYSEC',
            'ECOVOUCHER',
            'MONEYCARD24',
            'GSCASH',
            'EPAYCODE',
            'CASHIXIR',
            'JETON',
            'INSTANTBANKTRANSFER',
            'SOFORT',
            'IDEAL',
            'VISA_VOUCHER',
          ],
        },
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['service'],
      },
    },
    PiqLuxonpayWithdrawal: {
      type: 'object',
      properties: {
        recipientWalletId: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['recipientWalletId'],
      },
    },
    PiqSkrillDeposit: {
      type: 'object',
      properties: {
        email: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['email'],
      },
    },
    PiqNetellerDeposit: {
      type: 'object',
      properties: {
        account: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['account'],
      },
    },
    PiqEcopayzDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
    },
    PiqVenuspointDeposit: {
      type: 'object',
      properties: {
        username: {type: 'string'},
        password: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['username', 'password'],
      },
    },
    PiqVegaDeposit: {
      type: 'object',
      properties: {
        username: {type: 'string'},
        password: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['username', 'password'],
      },
    },
    PiqSwishDeposit: {
      type: 'object',
      properties: {
        swishNumber: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['swishNumber'],
      },
    },
    PiqZimplerDeposit: {
      type: 'object',
      properties: {
        phoneNumber: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['phoneNumber'],
      },
    },
    PiqPproDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['service'],
      },
    },
    PiqPproIdealDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['service'],
      },
    },
    PiqPugglepayDeposit: {
      type: 'object',
      properties: {
        phoneNumber: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['phoneNumber'],
      },
    },
    PiqBankDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
        nationalId: {type: 'string'},
      },
    },
    PiqBankBlDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
        nationalId: {type: 'string'},
      },
      required: ['service'],
    },
    PiqBankJcDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
        nationalId: {type: 'string'},
      },
      required: ['service'],
    },
    PiqBankBtvoucherDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      required: ['service'],
    },
    PiqBankBtvoucherAtmDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      required: ['service'],
    },
    PiqBankPpbtvoucherDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      required: ['service'],
    },
    PiqBankIxDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
        nationalId: {type: 'string'},
      },
      required: ['service'],
    },
    PiqBankBriteDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      required: ['service'],
    },
    PiqBankNbDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
        nationalId: {type: 'string'},
      },
      required: ['service'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['nationalId'],
      },
    },
    PiqBankUiDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
        nationalId: {type: 'string'},
      },
      required: ['service'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['nationalId'],
      },
    },
    PiqBankNetBankingDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
        bankName: {
          type: 'string',
          enum: [
            '1009',
            '1064',
            '1055',
            '1063',
            '1060',
            '1104',
            '1026',
            '1040',
            '1107',
            '1027',
            '1004',
            '1013',
            '1120',
            '1069',
            '1049',
            '1054',
            '1003',
            '1041',
            '1032',
            '1048',
            '1012',
            '1095',
            '1129',
            '1113',
            '1045',
            '1030',
            '1065',
            '1103',
            '1001',
          ],
        },
      },
      required: ['service', 'bankName'],
    },
    PiqBankPixDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
        nationalId: {type: 'string'},
      },
      required: ['service'],
    },
    PiqBankInovapayDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
        nationalId: {type: 'string'},
      },
      required: ['service'],
    },
    PiqBankDirecta24Deposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      required: ['service'],
    },
    PiqBankUpiDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
        bankName: {type: 'string'},
      },
      required: ['service', 'bankName'],
    },
    PiqBankVoltDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
        nationalId: {type: 'string'},
      },
      required: ['service'],
    },
    PiqBankVoltWithdrawal: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqBankWalletDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
        bankName: {type: 'string'},
      },
      required: ['service', 'bankName'],
    },
    PiqAstropaybankWithdrawal: {
      type: 'object',
      properties: {
        bankCode: {type: 'string'},
        nationalId: {type: 'string'},
        bankBranch: {type: 'string'},
        bankAccount: {type: 'string'},
        accountType: {type: 'string', enum: ['C', 'S']},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: [
          'bankCode',
          'nationalId',
          'bankBranch',
          'bankAccount',
          'accountType',
        ],
      },
    },
    PiqAstropaybankPixWithdrawal: {
      type: 'object',
      properties: {
        nationalId: {
          type: 'string',
          pattern: '^([0-9]{3}.?){3}-[0-9]{2}$',
        },
        service: {type: 'string'},
      },
      required: ['service'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['nationalId'],
      },
    },
    PiqAstropaybankD24indiaWithdrawal: {
      type: 'object',
      properties: {
        nationalId: {
          type: 'string',
          minLength: 8,
          maxLength: 16,
        },
        bankBranch: {
          type: 'string',
          pattern: '^[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$',
        },
        bankAccount: {
          type: 'string',
          pattern: '^[a-zA-Z0-9-.]{9,18}$',
        },
        service: {type: 'string'},
      },
      required: ['service'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['nationalId', 'bankBranch', 'bankAccount'],
      },
    },
    PiqBankInteracDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      required: ['service'],
    },
    PiqBankParamountDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      required: ['service'],
    },
    PiqBankNetbankingDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
        nationalId: {type: 'string'},
      },
    },
    PiqWebredirectMandatoDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqWebredirectMandatoWithdrawal: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqBankRedeemVoucherDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqInovapaywalletDeposit: {
      type: 'object',
      properties: {
        userLogin: {type: 'string'},
        userSecureId: {type: 'string'},
      },
      required: ['userSecureId'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['userLogin', 'userSecureId'],
      },
    },
    PiqInovapaybankWithdrawal: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
          enum: ['BANCODOBRASIL', 'BRADESCO', 'CAIXA', 'ITAU', 'SANTANDER'],
        },
        nationalId: {
          type: 'string',
        },
        branchCode: {
          type: 'string',
        },
        accountNumber: {
          type: 'string',
        },
        accountType: {
          type: 'string',
          enum: ['C', 'S'],
        },
      },
      required: ['accountId', 'amount'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: [
          'service',
          'nationalId',
          'branchCode',
          'accountNumber',
          'accountType',
        ],
      },
    },
    PiqInovapaybankPixWithdrawal: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
        nationalId: {
          type: 'string',
        },
        pixKey: {
          type: 'string',
        },
      },
      required: ['accountId', 'amount'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['service', 'nationalId', 'pixKey'],
      },
    },
    PiqInovapaywalletWithdrawal: {
      type: 'object',
      properties: {
        amount: {
          type: 'number',
        },
        accountId: {
          type: 'string',
        },
        provider: {
          type: 'string',
        },
        userLogin: {
          type: 'string',
        },
        userSecureId: {
          type: 'string',
        },
      },
      required: ['accountId', 'amount'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['userLogin', 'userSecureId'],
      },
    },
    PiqGate2waywalletPaytmWithdrawal: {
      type: 'object',
      properties: {
        walletName: {
          type: 'string',
        },
        walletNumber: {
          type: 'string',
        },
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['walletName', 'walletNumber', 'service'],
      },
    },
    PiqInstadebitDeposit: {type: 'object'},
    PiqSofortDeposit: {type: 'object'},
    PiqMifinityDeposit: {type: 'object'},
    PiqMifinityUnionpayDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      required: ['service'],
    },
    PiqMifinityKlarnaDeposit: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      required: ['service'],
    },
    PiqMuchbetterDeposit: {
      type: 'object',
      properties: {
        phoneNumber: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['phoneNumber'],
      },
    },
    PiqMuchbetterWithdrawal: {
      type: 'object',
      properties: {
        phoneNumber: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['phoneNumber'],
      },
    },
    PiqEutellerDeposit: {type: 'object'},
    PiqCryptocurrencyDeposit: {
      type: 'object',
      properties: {
        cryptoCurrency: {
          type: 'string',
          enum: [
            'USDT',
            'BTC',
            'ETH',
            'TRX',
            'XRP',
            'BNB',
            'LTC',
            'ADA',
            'DOGE',
            'XLM',
            'BCH',
            'SOL',
            'SHIB',
            'USDC',
            'DAI',
          ],
        },
        network: {
          type: 'string',
        },
      },
      allOf: [
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'USDT'},
            },
          },
          then: {
            properties: {
              network: {
                type: 'string',
                enum: ['ethereum', 'tron', 'bnb_smart_chain', 'solana'],
              },
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'BTC'},
            },
          },
          then: {
            properties: {
              network: {enum: ['bitcoin', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'LTC'},
            },
          },
          then: {
            properties: {
              network: {enum: ['litecoin', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'ETH'},
            },
          },
          then: {
            properties: {
              network: {enum: ['ethereum', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'XRP'},
            },
          },
          then: {
            properties: {
              network: {enum: ['ripple', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'XLM'},
            },
          },
          then: {
            properties: {
              network: {enum: ['stellar', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'BCH'},
            },
          },
          then: {
            properties: {
              network: {enum: ['bitcoin_cash', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'ADA'},
            },
          },
          then: {
            properties: {
              network: {enum: ['cardano', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'SOL'},
            },
          },
          then: {
            properties: {
              network: {enum: ['solana', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'SHIB'},
            },
          },
          then: {
            properties: {
              network: {enum: ['ethereum', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'TRX'},
            },
          },
          then: {
            properties: {
              network: {enum: ['tron', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'DOGE'},
            },
          },
          then: {
            properties: {
              network: {enum: ['dogecoin', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'BNB'},
            },
          },
          then: {
            properties: {
              network: {enum: ['bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'USDC'},
            },
          },
          then: {
            properties: {
              network: {enum: ['ethereum', 'bnb_smart_chain', 'solana']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'DAI'},
            },
          },
          then: {
            properties: {
              network: {enum: ['ethereum', 'bnb_smart_chain']},
            },
          },
        },
      ],
      required: ['cryptoCurrency', 'network'],
    },
    PiqCryptocurrencyBitpaceDeposit: {
      type: 'object',
      properties: {
        cryptoCurrency: {
          type: 'string',
          enum: ['BTC', 'LTC', 'ETH', 'XRP'],
        },
        service: {type: 'string'},
      },
      required: ['cryptoCurrency', 'service'],
    },
    PiqCryptocurrencyCryptopayDeposit: {
      type: 'object',
      properties: {
        cryptoCurrency: {
          type: 'string',
          enum: [
            'BTC',
            'USDT',
            'ETH',
            'LTC',
            'XRP',
            'USDC',
            'BCH',
            'DAI',
            'XLM',
          ],
        },
        service: {type: 'string'},
      },
      required: ['cryptoCurrency', 'service'],
    },
    PiqEnvoyDeposit: {
      type: 'object',
      properties: {
        country: {type: 'string'},
        service: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['country', 'service'],
      },
    },
    PiqSiirtoDeposit: {
      type: 'object',
      properties: {
        phoneNumber: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['phoneNumber'],
      },
    },
    PiqEcobanqDeposit: {
      type: 'object',
      properties: {
        ecoBanqUserId: {type: 'string'},
        ecoBanqPassword: {type: 'string'},
      },
      required: ['ecoBanqUserId', 'ecoBanqPassword'],
    },
    PiqPay4funWithdrawal: {
      type: 'object',
      properties: {
        email: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['email'],
      },
    },
    PiqSkrillqcoDeposit: {
      type: 'object',
      properties: {
        accountId: {
          type: 'string',
        },
        email: {
          type: 'string',
        },
        service: {
          type: 'string',
        },
        secureId: {
          type: 'string',
        },
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectIbDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectPneDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqCryptocurrencyWithdrawal: {
      type: 'object',
      properties: {
        cryptoCurrency: {
          type: 'string',
          enum: [
            'USDT',
            'BTC',
            'ETH',
            'TRX',
            'XRP',
            'BNB',
            'LTC',
            'ADA',
            'DOGE',
            'XLM',
            'BCH',
            'SOL',
            'SHIB',
            'USDC',
            'DAI',
          ],
        },
        network: {type: 'string'},
        walletAddress: {type: 'string'},
        destinationTag: {
          type: 'string',
          minLength: 1,
          maxLength: 10,
          pattern: '^[a-z0-9]+$',
        },
      },
      allOf: [
        {
          if: {
            properties: {accountId: {const: ''}},
          },
          then: {
            required: ['walletAddress'],
          },
        },
        {
          if: {
            properties: {
              accountId: {const: ''},
              cryptoCurrency: {const: 'XRP'},
            },
          },
          then: {
            properties: {
              network: {enum: ['ripple', 'bnb_smart_chain']},
              required: ['destinationTag', 'walletAddress'],
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'USDT'},
            },
          },
          then: {
            properties: {
              network: {
                type: 'string',
                enum: ['ethereum', 'tron', 'bnb_smart_chain', 'solana'],
              },
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'BTC'},
            },
          },
          then: {
            properties: {
              network: {enum: ['bitcoin', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'LTC'},
            },
          },
          then: {
            properties: {
              network: {enum: ['litecoin', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'ETH'},
            },
          },
          then: {
            properties: {
              network: {enum: ['ethereum', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'XLM'},
            },
          },
          then: {
            properties: {
              network: {enum: ['stellar', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'BCH'},
            },
          },
          then: {
            properties: {
              network: {enum: ['bitcoin_cash', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'ADA'},
            },
          },
          then: {
            properties: {
              network: {enum: ['cardano', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'SOL'},
            },
          },
          then: {
            properties: {
              network: {enum: ['solana', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'SHIB'},
            },
          },
          then: {
            properties: {
              network: {enum: ['ethereum', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'TRX'},
            },
          },
          then: {
            properties: {
              network: {enum: ['tron', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'DOGE'},
            },
          },
          then: {
            properties: {
              network: {enum: ['dogecoin', 'bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'BNB'},
            },
          },
          then: {
            properties: {
              network: {enum: ['bnb_smart_chain']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'USDC'},
            },
          },
          then: {
            properties: {
              network: {enum: ['ethereum', 'bnb_smart_chain', 'solana']},
            },
          },
        },
        {
          if: {
            properties: {
              cryptoCurrency: {const: 'DAI'},
            },
          },
          then: {
            properties: {
              network: {enum: ['ethereum', 'bnb_smart_chain']},
            },
          },
        },
      ],
    },
    PiqCryptocurrencyBitpaceWithdrawal: {
      type: 'object',
      properties: {
        cryptoCurrency: {
          type: 'string',
          enum: ['BTC', 'LTC', 'ETH', 'XRP'],
        },
        walletAddress: {type: 'string'},
        service: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['walletAddress', 'service'],
      },
    },
    PiqCryptocurrencyCryptopayWithdrawal: {
      type: 'object',
      properties: {
        cryptoCurrency: {
          type: 'string',
          enum: ['BTC', 'USDT', 'ETH', 'LTC', 'XRP', 'USDC', 'BCH', 'DAI'],
        },
        walletAddress: {type: 'string'},
        destinationTag: {
          type: 'string',
          minLength: 1,
          maxLength: 10,
          pattern: '^[a-z0-9]+$',
        },
        service: {type: 'string'},
      },
      allOf: [
        {
          if: {
            properties: {accountId: {const: ''}},
          },
          then: {
            required: ['service', 'walletAddress'],
          },
        },
        {
          if: {
            properties: {
              accountId: {const: ''},
              cryptoCurrency: {const: 'XRP'},
            },
          },
          then: {
            required: ['destinationTag', 'service', 'walletAddress'],
          },
        },
      ],
    },
    PiqInstadebitWithdrawal: {
      type: 'object',
      properties: {
        accountId: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['accountId', 'provider'],
      },
    },
    PiqIdebitWithdrawal: {
      type: 'object',
      properties: {
        accountId: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['accountId', 'provider'],
      },
    },
    PiqSiirtoWithdrawal: {
      type: 'object',
      properties: {
        phoneNumber: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['phoneNumber'],
      },
    },
    PiqCreditcardWithdrawal: {
      type: 'object',
      properties: {
        cardHolder: {
          type: 'string',
        },
        encCreditcardNumber: {
          type: 'string',
          pattern: '^[0-9]+$',
          format: 'creditCard',
        },
        encCvv: {
          type: 'string',
          pattern: '^[0-9]+$',
        },
        expiryMonth: {
          type: 'string',
          format: 'expiryMonth',
          maxLength: 2,
          minLength: 2,
          maximum: 12,
          minimum: 1,
        },
        expiryYear: {
          type: 'string',
          format: 'expiryYear',
          maxLength: 4,
          minLength: 4,
        },
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        allOf: [
          {
            if: {
              properties: {encCreditcardNumber: {pattern: '^[1-6]'}},
            },
            then: {
              properties: {
                encCreditcardNumber: {
                  minLength: 12,
                  maxLength: 19,
                },
              },
            },
            else: {
              properties: {
                encCreditcardNumber: {
                  minLength: 16,
                  maxLength: 16,
                },
              },
            },
          },
          {
            if: {
              properties: {encCreditcardNumber: {pattern: '^(34|37)'}},
            },
            then: {
              properties: {
                encCvv: {
                  minLength: 4,
                  maxLength: 4,
                },
              },
            },
            else: {
              properties: {
                encCvv: {
                  minLength: 3,
                  maxLength: 3,
                },
              },
            },
          },
        ],
        required: [
          'cardHolder',
          'encCreditcardNumber',
          'encCvv',
          'expiryMonth',
          'expiryYear',
          'provider',
        ],
      },
      else: {
        properties: {
          encCvv: {
            minLength: 3,
            maxLength: 4,
          },
        },
      },
    },
    PiqParamountWithdrawal: {
      type: 'object',
      properties: {
        securityQuestion: {
          type: 'string',
          minLength: 6,
          maxLength: 40,
          enum: [
            'Your favorite city',
            "Your childhood best friend's name",
            'The name of the street you grow up on',
            'The name of your elementary school',
            'Your favourite food',
            'The make of your first car',
            'Your favourite drink',
          ],
        },
        securityAnswer: {
          type: 'string',
          minLength: 6,
          maxLength: 25,
        },
      },
      required: ['securityQuestion', 'securityAnswer'],
    },
    PiqTrustlyWithdrawal: {type: 'object'},
    PiqWebredirectDeposit: {
      type: 'object',
      properties: {
        email: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['email'],
      },
    },
    PiqWebredirectSparkasseDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
        providerId: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectVolksbankenDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
        providerId: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectDeutschebankDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
        providerId: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectPostbankDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
        providerId: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectCommerzbankDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
        providerId: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectDeutschekreditbankDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
        providerId: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectOpDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectOnlineBankingDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqWebredirectApplepayDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqWebredirectGooglepayDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqWebredirectColibrixGooglepayDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqWebredirectColibrixApplepayDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqWebredirectTrustlyDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqWebredirectEbpDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectMacropayDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectNodapayDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectBoletoDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectBoletoPixDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectBtDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqEzeebillBtjpnWithdrawal: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
        accountNumber: {type: 'string'},
        bankName: {type: 'string'},
        bankCode: {type: 'string'},
        branchName: {type: 'string'},
        branchCode: {type: 'string'},
        beneficiaryName: {type: 'string'},
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: [
          'service',
          'accountNumber',
          'bankName',
          'bankCode',
          'branchName',
          'branchCode',
          'beneficiaryName',
        ],
      },
    },
    PiqWebredirectJpayDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectZotapayDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectOnrampDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectPixOnlineDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectQuickbitDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectSofortDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectOnlineDebitDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectDirectPaymentDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectUpiH5Deposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectPaytmWalletDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectPhonepeWalletDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectMobikwikWalletDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectAirtelWalletDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectJioWalletDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectCreditCardDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectUpiWithdrawal: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectLocalBankTransferWithdrawal: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectSumopayDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectFlykkDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectFinsupportDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqCryptocurrencyFinsupportDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqWebredirectPagavaDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectBdbanksDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectVisaDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectMastercardDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectRupayDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectUpiDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectIdealDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqWebredirectIdealBetapinDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqWebredirectPaypalBetapinDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqWebredirectPaypalDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqWebredirectSticpayDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectZotacardDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectPaytmDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectImpsDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectGpayDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectPhonepeDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectTigerpayDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectBtbDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectBtbAutoDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectTigerpayWithdrawal: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectOnrampWithdrawal: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectQaicashDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectQaicashWithdrawal: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectPointinoutDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectQpointsDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectPointinoutWithdrawal: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectQpointsWithdrawal: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqIwalletWithdrawal: {
      type: 'object',
      properties: {
        destinationAccount: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['destinationAccount'],
      },
    },
    PiqInteracWithdrawal: {type: 'object'},
    PiqInterkassawalletUpiDeposit: {
      type: 'object',
      properties: {
        walletNumber: {
          type: 'string',
          minLength: 5,
          maxLength: 45,
          pattern: '^.+@.+$',
        },
        service: {type: 'string'},
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['walletNumber', 'service'],
      },
    },
    PiqInterkassawalletUpiWithdrawal: {
      type: 'object',
      properties: {
        walletNumber: {
          type: 'string',
          minLength: 5,
          maxLength: 45,
          pattern: '^.+@.+$',
        },
        service: {type: 'string'},
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['walletNumber', 'service'],
      },
    },
    PiqInterkassawalletWalletDeposit: {
      type: 'object',
      properties: {
        walletNumber: {
          type: 'string',
          enum: ['101', '102', '103', '106', '107', '113', '115'],
        },
        service: {type: 'string'},
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['walletNumber', 'service'],
      },
    },
    PiqSkrillWithdrawal: {
      type: 'object',
      properties: {
        email: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['email'],
      },
    },
    PiqSkrillqcoWithdrawal: {
      type: 'object',
      properties: {
        email: {type: 'string'},
        accountId: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['service', 'accountId'],
      },
    },
    PiqNetellerWithdrawal: {
      type: 'object',
      properties: {
        account: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['account'],
      },
    },
    PiqEcopayzWithdrawal: {
      type: 'object',
      properties: {
        accountNumber: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['accountNumber'],
      },
    },
    PiqVenuspointWithdrawal: {
      type: 'object',
      properties: {
        username: {type: 'string'},
        password: {type: 'string'},
      },
      required: ['password'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['username'],
      },
    },
    PiqVegaWithdrawal: {
      type: 'object',
      properties: {
        username: {type: 'string'},
        password: {type: 'string'},
      },
      required: ['password'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['username'],
      },
    },
    PiqZimplerWithdrawal: {
      type: 'object',
      properties: {
        phoneNumber: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['phoneNumber'],
      },
    },
    PiqPugglepayWithdrawal: {
      type: 'object',
      properties: {
        phoneNumber: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['phoneNumber'],
      },
    },
    PiqPayretailersWithdrawal: {
      type: 'object',
      properties: {
        bankName: {
          type: 'string',
          enum: [
            '001',
            '341',
            '033',
            '237',
            '745',
            '104',
            '422',
            '748',
            '041',
            '208',
            '655',
            '735',
            '007',
            '011',
            '077',
            '121',
            '212',
            '260',
            '276',
            '336',
          ],
        },
        beneficiaryName: {type: 'string'},
        accountAgencyNumber: {type: 'string'},
        accountNumber: {type: 'string'},
        documentNumber: {type: 'string'},
        documentType: {type: 'string'},
        accountType: {type: 'string', enum: ['0001', '0002']},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: [
          'accountAgencyNumber',
          'accountNumber',
          'accountType',
          'documentNumber',
          'documentType',
          'bankName',
        ],
      },
    },
    PiqPayretailersPixWithdrawal: {
      type: 'object',
      properties: {
        service: {type: 'string'},
        bankName: {
          type: 'string',
          enum: [
            '001',
            '341',
            '033',
            '237',
            '745',
            '104',
            '422',
            '748',
            '041',
            '208',
            '655',
            '735',
            '007',
            '011',
            '077',
            '121',
            '212',
            '260',
            '276',
            '336',
          ],
        },
        beneficiaryName: {type: 'string'},
        accountAgencyNumber: {type: 'string'},
        accountNumber: {type: 'string'},
        documentNumber: {type: 'string'},
        documentType: {type: 'string'},
        accountType: {type: 'string', enum: ['0001', '0002']},
        recipientPixKey: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: [
          'service',
          'accountAgencyNumber',
          'accountNumber',
          'accountType',
          'documentNumber',
          'documentType',
          'bankName',
          'recipientPixKey',
        ],
      },
    },
    PiqBanklocalWithdrawal: {
      type: 'object',
      properties: {
        accountNumber: {type: 'string'},
        bankName: {type: 'string'},
        beneficiaryName: {type: 'string'},
        clearingNumber: {type: 'string'},
        countryCode: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: [
          'accountNumber',
          'bankName',
          'beneficiaryName',
          'clearingNumber',
        ],
      },
    },
    PiqBanklocalInterkassaWithdrawal: {
      type: 'object',
      properties: {
        service: {type: 'string'},
        accountId: {type: 'string'},
        amount: {type: 'number'},
        accountNumber: {
          type: 'string',
          minLength: 8,
          maxLength: 18,
        },
        clearingNumber: {
          type: 'string',
          minLength: 11,
          maxLength: 11,
        },
      },
      required: ['amount'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['accountNumber', 'clearingNumber', 'service'],
      },
    },
    PiqBanklocalInpayjpWithdrawal: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
        accountId: {
          type: 'string',
        },
        amount: {
          type: 'number',
        },
        provider: {
          type: 'string',
        },
        accountNumber: {
          type: 'string',
          minLength: 5,
          maxLength: 9,
        },
        bankName: {
          type: 'string',
          pattern: '^[a-zA-Z0-9\\s.,!@\\-_:;\\(\\)]+$', // only english characters
        },
        beneficiaryName: {
          type: 'string',
          minLength: 1,
          pattern: '^[a-zA-Z0-9\\s.,!@\\-_:;\\(\\)]+$', // only english characters
        },
        clearingNumber: {
          type: 'string',
          minLength: 6,
          maxLength: 8,
        },
        countryCode: {
          type: 'string',
        },
      },
      required: ['amount'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: [
          'accountNumber',
          'bankName',
          'beneficiaryName',
          'clearingNumber',
          'provider',
          'service',
        ],
      },
    },
    PiqBanklocalUpiWithdrawal: {
      type: 'object',
      properties: {
        service: {type: 'string'},
        accountId: {type: 'string'},
        amount: {type: 'number'},
        accountNumber: {
          type: 'string',
          minLength: 9,
          maxLength: 18,
          pattern: '^[a-zA-Z0-9\\s.,!@\\-_:;\\(\\)]+$', // only english characters
        },
        bankName: {
          type: 'string',
          pattern: '^[a-zA-Z0-9\\s.,!@\\-_:;\\(\\)]+$', // only english characters
        },
        beneficiaryName: {
          type: 'string',
          pattern: '^[a-zA-Z0-9\\s.,!@\\-_:;\\(\\)]+$', // only english characters
        },
        clearingNumber: {
          type: 'string',
          minLength: 11,
          maxLength: 11,
          pattern: '^[a-zA-Z0-9\\s.,!@\\-_:;\\(\\)]+$', // only english characters
        },
        countryCode: {type: 'string'},
      },
      required: ['amount'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: [
          'accountNumber',
          'bankName',
          'beneficiaryName',
          'clearingNumber',
          'service',
        ],
      },
    },
    PiqBanklocalBtbWithdrawal: {
      type: 'object',
      properties: {
        service: {type: 'string'},
        accountId: {type: 'string'},
        amount: {type: 'number'},
        accountNumber: {
          type: 'string',
          pattern: '^[a-zA-Z0-9\\s.,!@\\-_:;\\(\\)]+$', // only english characters
        },
        bankName: {
          type: 'string',
          pattern: '^[a-zA-Z0-9\\s.,!@\\-_:;\\(\\)]+$', // only english characters
        },
        beneficiaryName: {
          type: 'string',
          pattern: '^[a-zA-Z0-9\\s.,!@\\-_:;\\(\\)]+$', // only english characters
        },
        clearingNumber: {
          type: 'string',
          pattern: '^[a-zA-Z0-9\\s.,!@\\-_:;\\(\\)]+$', // only english characters
        },
        countryCode: {type: 'string'},
      },
      required: ['amount'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: [
          'accountNumber',
          'bankName',
          'beneficiaryName',
          'clearingNumber',
          'service',
        ],
      },
    },
    PiqBanklocalLocalBankTransferWithdrawal: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
        accountId: {
          type: 'string',
        },
        amount: {
          type: 'number',
        },
        provider: {
          type: 'string',
        },
        accountNumber: {
          type: 'string',
          minLength: 5,
          maxLength: 9,
        },
        bankName: {
          type: 'string',
          pattern: '^[a-zA-Z0-9\\s.,!@\\-_:;\\(\\)]+$', // only english characters
        },
        beneficiaryName: {
          type: 'string',
          minLength: 1,
          pattern: '^[a-zA-Z0-9\\s.,!@\\-_:;\\(\\)]+$', // only english characters
        },
        clearingNumber: {
          type: 'string',
          minLength: 6,
          maxLength: 8,
        },
        countryCode: {
          type: 'string',
        },
      },
      required: ['amount'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: [
          'accountNumber',
          'bankName',
          'beneficiaryName',
          'clearingNumber',
          'provider',
          'service',
        ],
      },
    },
    PiqBanklocalSumopayWithdrawal: {
      type: 'object',
      properties: {
        accountNumber: {type: 'string'},
        bankName: {type: 'string'},
        beneficiaryName: {type: 'string'},
        clearingNumber: {type: 'string'},
        countryCode: {type: 'string'},
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: [
          'accountNumber',
          'bankName',
          'beneficiaryName',
          'clearingNumber',
          'service',
        ],
      },
    },
    PiqBanklocalPagavaWithdrawal: {
      type: 'object',
      properties: {
        accountNumber: {type: 'string'},
        bankName: {type: 'string'},
        beneficiaryName: {type: 'string'},
        clearingNumber: {type: 'string'},
        countryCode: {type: 'string'},
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: [
          'accountNumber',
          'bankName',
          'beneficiaryName',
          'clearingNumber',
          'service',
        ],
      },
    },
    PiqBanklocalImpsWithdrawal: {
      type: 'object',
      properties: {
        service: {type: 'string'},
        accountId: {type: 'string'},
        amount: {type: 'number'},
        accountNumber: {
          type: 'string',
          minLength: 9,
          maxLength: 18,
          pattern: '^[a-zA-Z0-9\\s.,!@\\-_:;\\(\\)]+$', // only english characters
        },
        bankName: {
          type: 'string',
          pattern: '^[a-zA-Z0-9\\s.,!@\\-_:;\\(\\)]+$', // only english characters
        },
        beneficiaryName: {
          type: 'string',
          pattern: '^[a-zA-Z0-9\\s.,!@\\-_:;\\(\\)]+$', // only english characters
        },
        clearingNumber: {
          type: 'string',
          minLength: 11,
          maxLength: 11,
          pattern: '^[a-zA-Z0-9\\s.,!@\\-_:;\\(\\)]+$', // only english characters
        },
        countryCode: {type: 'string'},
      },
      required: ['amount'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: [
          'accountNumber',
          'bankName',
          'beneficiaryName',
          'clearingNumber',
          'service',
        ],
      },
    },
    PiqBanklocalVipwithdrawalJpWithdrawal: {
      type: 'object',
      properties: {
        service: {type: 'string'},
        accountNumber: {type: 'string'},
        bankName: {type: 'string'},
        beneficiaryName: {type: 'string'},
        branchName: {type: 'string'},
        clearingNumber: {type: 'string'},
        localBeneficiaryName: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: [
          'accountNumber',
          'bankName',
          'beneficiaryName',
          'branchName',
          'clearingNumber',
          'localBeneficiaryName',
        ],
      },
    },
    PiqBankintlWithdrawal: {
      type: 'object',
      properties: {
        accountNumber: {type: 'string'},
        bic: {type: 'string'},
        beneficiaryName: {type: 'string'},
        beneficiaryStreet: {type: 'string'},
        beneficiaryZip: {type: 'string'},
        beneficiaryCity: {type: 'string'},
        beneficiaryState: {type: 'string'},
        beneficiaryCountry: {type: 'string'},
        bankName: {type: 'string'},
        branchName: {type: 'string'},
        branchCode: {type: 'string'},
        branchAddress: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['accountNumber', 'bic'],
      },
    },
    PiqWebredirectSofortuberweisungDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqBankBriteWithdrawal: {
      type: 'object',
      properties: {
        service: {type: 'string'},
      },
      required: ['service'],
    },
    PiqBankintlInpayWithdrawal: {
      type: 'object',
      properties: {
        accountNumber: {type: 'string'},
        bic: {type: 'string'},
        beneficiaryName: {type: 'string'},
        beneficiaryStreet: {type: 'string'},
        beneficiaryZip: {type: 'string'},
        beneficiaryCity: {type: 'string'},
        beneficiaryState: {type: 'string'},
        beneficiaryCountry: {type: 'string'},
        bankName: {type: 'string'},
        branchName: {type: 'string'},
        branchCode: {type: 'string'},
        branchAddress: {type: 'string'},
        service: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['accountNumber', 'bic', 'service'],
      },
    },
    PiqBankintlNzdWithdrawal: {
      type: 'object',
      properties: {
        accountNumber: {type: 'string'},
        bic: {type: 'string'},
        bankName: {type: 'string'},
        branchAddress: {type: 'string'},
        service: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['accountNumber', 'bic', 'service'],
      },
    },
    PiqBankintlNetbankingWithdrawal: {
      type: 'object',
      properties: {
        accountNumber: {type: 'string'},
        bic: {type: 'string'},
        beneficiaryName: {type: 'string'},
        beneficiaryStreet: {type: 'string'},
        beneficiaryZip: {type: 'string'},
        beneficiaryCity: {type: 'string'},
        beneficiaryState: {type: 'string'},
        beneficiaryCountry: {type: 'string'},
        bankName: {
          type: 'string',
          enum: [
            'Allahabad Bank',
            'Andhra Bank',
            'Andhra Pradesh Grameena Vikas Ba',
            'Andhra Pragathi Grameena Bank',
            'Arunachal Pradesh Rural Bank',
            'Aryavart Gramin Bank',
            'Assam Gramin Vikash Bank',
            'AU Small Finance Bank',
            'Axis Bank',
            'Bandhan Bank',
            'Bangiya Gramin Vikash Bank',
            'Bank of Baroda',
            'Bank of India',
            'Bank of Maharashtra',
            'Baroda Gujarat Gramin Bank',
            'Canara Bank',
            'Capital Small Finance Bank',
            'Catholic Syrian Bank',
            'Central Bank of India',
            'Chaitanya Godavari Gramin Bank',
            'Chhattisgarh Rajya Gramin Bank',
            'City Union Bank',
            'Corporation Bank',
            'DBS Bank',
            'DCB Bank',
            'Dena Bank',
            'Deutsche Bank',
            'Dhanlaxmi Bank',
            'Ellaquai Dehati Bank',
            'Equitas Small Finance Bank',
            'ESAF Small Finance Bank',
            'Federal Bank',
            'Fincare Small Finance Bank',
            'HDFC Bank',
            'Himachal Pradesh Gramin Bank',
            'HSBC Bank',
            'ICICI Bank',
            'IDBI Bank',
            'IDFC First Bank',
            'Indian Bank',
            'Indian Overseas Bank',
            'IndusInd Bank',
            'Jammu & Kashmir Bank',
            'Jana Small Finance Bank',
            'Jharkhand Rajya Gramin Bank',
            'Karnataka Bank',
            'Karnataka Vikas Grameena Bank',
            'Karur Vysya Bank',
            'Kerala Gramin Bank',
            'Kotak Mahindra Bank',
            'Lakshmi Vilas Bank',
            'Madhyanchal Gramin Bank',
            'Maharashtra Gramin Bank',
            'Meghalaya Rural Bank',
            'Nainital Bank',
            'Oriental Bank of Commerce',
            'Paschim Banga Gramin Bank',
            'Punjab and Sind Bank',
            'Punjab National Bank',
            'RBL Bank',
            'Saptagiri Gramin Bank',
            'Sarva Haryana Gramin Bank',
            'Saurashtra Gramin Bank',
            'South Indian Bank',
            'Standard Chartered Bank',
            'State Bank of India',
            'Suryoday Small Finance Bank',
            'Syndicate Bank',
            'Tamilnad Mercantile Bank',
            'Telangana Grameena Bank',
            'Tripura Gramin Bank',
            'UCO Bank',
            'Ujjivan Small Finance Bank',
            'Union Bank of India',
            'United Bank of India',
            'Uttar Bihar Gramin Bank',
            'Uttarakhand Gramin Bank',
            'Uttarbanga Kshetriya Gramin Bank',
            'Vidarbha Konkan Gramin Bank',
            'Vijaya Bank',
            'Yes Bank',
          ],
        },
        branchName: {type: 'string'},
        branchCode: {type: 'string'},
        branchAddress: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['accountNumber', 'bic'],
      },
    },
    PiqBestpaytransferWithdrawal: {
      type: 'object',
      properties: {
        accountNumber: {type: 'string'},
        branchCode: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['accountNumber', 'branchCode'],
      },
    },
    PiqPaymerobankWithdrawal: {
      type: 'object',
      properties: {
        accountNumber: {type: 'string'},
        beneficiaryName: {type: 'string'},
        bankName: {type: 'string'},
        ifscCode: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['accountNumber', 'beneficiaryName', 'bankName', 'ifscCode'],
      },
    },
    PiqSticpayWithdrawal: {
      type: 'object',
      properties: {
        email: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['email'],
      },
    },
    PiqEnvoyWithdrawal: {
      type: 'object',
      properties: {
        accountHolder: {type: 'string'},
        accountNumberOrIban: {type: 'string'},
        accountType: {type: 'string'},
        additionalInfo: {type: 'string'},
        bankCode: {type: 'string'},
        bankName: {type: 'string'},
        branchAddress: {type: 'string'},
        branchCode: {type: 'string'},
        checkDigits: {type: 'string'},
        countryCode: {type: 'string'},
        swift: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: [
          'accountHolder',
          'accountNumberOrIban',
          'accountType',
          'additionalInfo',
          'bankCode',
          'bankName',
          'branchAddress',
          'branchCode',
          'checkDigits',
          'countryCode',
          'swift',
        ],
      },
    },
    PiqWebredirectWithdrawal: {
      type: 'object',
      properties: {
        email: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['email'],
      },
    },
    PiqBankdomesticWithdrawal: {
      type: 'object',
      properties: {
        accountNumber: {type: 'string'},
        bankName: {type: 'string'},
        beneficiaryName: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['accountNumber', 'bankName', 'beneficiaryName'],
      },
    },
    PiqEcobanqWithdrawal: {
      type: 'object',
      properties: {
        ecoBanqUserId: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['ecoBanqUserId'],
      },
    },
    PiqBankibanWithdrawal: {
      type: 'object',
      properties: {
        bankName: {type: 'string'},
        beneficiaryName: {type: 'string'},
        bic: {type: 'string'},
        countryCode: {type: 'string'},
        iban: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['bic', 'beneficiaryName', 'iban'],
      },
    },
    PiqBankibanBriteWithdrawal: {
      type: 'object',
      properties: {
        bankName: {type: 'string'},
        beneficiaryName: {type: 'string'},
        bic: {type: 'string'},
        countryCode: {type: 'string'},
        iban: {type: 'string'},
        service: {type: 'string'},
      },
      required: ['service'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['bic', 'beneficiaryName', 'iban', 'service'],
      },
    },
    PiqBankibanEbpWithdrawal: {
      type: 'object',
      properties: {
        beneficiaryName: {type: 'string'},
        bic: {type: 'string'},
        iban: {type: 'string'},
        service: {type: 'string'},
      },
      required: ['service'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['bic', 'beneficiaryName', 'iban', 'service'],
      },
    },
    PiqBankibanNodapayWithdrawal: {
      type: 'object',
      properties: {
        beneficiaryName: {type: 'string'},
        bic: {type: 'string'},
        iban: {type: 'string'},
        service: {type: 'string'},
      },
      required: ['service'],
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['bic', 'beneficiaryName', 'iban', 'service'],
      },
    },
    PiqMifinityWithdrawal: {
      type: 'object',
      properties: {
        destinationAccount: {type: 'string'},
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['destinationAccount'],
      },
    },
    ProjsBankidAuth: {
      type: 'object',
      properties: {
        bank_id_type: {
          type: 'string',
          enum: ['local', 'remote_personal_number', 'remote_qr_code'],
        },
        personal_number: {
          type: 'string',
          format: 'personalNumber',
          maxLength: 13,
          minLength: 12,
          pattern: '^(18|19|20)[0-9]{6}-?[0-9]{4}$',
        },
      },
      required: ['bank_id_type'],
      allOf: [
        {
          if: {
            properties: {bank_id_type: {const: 'remote_personal_number'}},
          },
          then: {
            required: ['personal_number'],
          },
        },
      ],
    },
    ProjsSwishDeposit: {
      type: 'object',
      properties: {
        bank_id_type: {
          type: 'string',
          enum: ['local', 'remote_personal_number', 'remote_qr_code'],
        },
        personal_number: {
          type: 'string',
          format: 'personalNumber',
          maxLength: 13,
          minLength: 12,
          pattern: '^(18|19|20)[0-9]{6}-?[0-9]{4}$',
        },
        phone_number: {
          type: 'string',
          minLength: 1,
          anyOf: [
            {
              pattern: '^\\+46\\d{9}$',
            },
            {
              pattern: '^0\\d{9}$',
            },
          ],
        },
      },
      required: ['bank_id_type'],
      allOf: [
        {
          if: {
            properties: {bank_id_type: {const: 'remote_personal_number'}},
          },
          then: {
            required: ['personal_number'],
          },
        },
        {
          if: {
            properties: {
              bank_id_type: {
                enum: ['remote_personal_number', 'remote_qr_code'],
              },
            },
          },
          then: {
            required: ['phone_number'],
          },
        },
      ],
    },
    PiqWebredirectGatowayDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      required: ['service'],
    },
    PiqWebredirectGate2wayDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectGate2wayskrillDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectGate2waynetellerDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectGate2waygiropayDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectGate2waypaysafecardDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectGate2waypaysafecashDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectGate2waysofortDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    PiqWebredirectGate2waywebpayzDeposit: {
      type: 'object',
      properties: {
        service: {
          type: 'string',
        },
      },
      if: {
        properties: {
          accountId: {
            const: '',
          },
        },
      },
      then: {
        required: ['service'],
      },
    },
    TransferworldTransferworldDeposit: {
      type: 'object',
    },
    TransferworldTransferworldWithdrawal: {
      type: 'object',
      properties: {
        beneficiaryName: {
          type: 'string',
          minLength: 2,
          maxLength: 255,
          pattern: "^[A-Za-z0-9,.:'-/ ()]+$",
        },
        swift: {
          type: 'string',
          minLength: 8,
          maxLength: 11,
        },
        iban: {
          type: 'string',
          minLength: 5,
          maxLength: 34,
        },
      },
      if: {
        properties: {accountId: {const: ''}},
      },
      then: {
        required: ['swift', 'beneficiaryName', 'iban'],
      },
    },
  },
  type: 'object',
  properties: {
    accountId: {
      type: 'string',
    },
    amount: {
      type: 'number',
    },
    bonusOffer: {
      type: 'number',
    },
    campaign: {
      type: 'number',
    },
    provider: {
      type: 'string',
    },
  },
  required: ['accountId', 'amount', 'provider'],
  allOf: [
    {
      if: {
        properties: {
          provider: {
            const: 'PiqAstropaycardGoDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqAstropaycardGoDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqAstropaycardNbDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqAstropaycardNbDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqAstropaycardPhDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqAstropaycardPhDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqAstropaycardTmDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqAstropaycardTmDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqAstropaycardUiDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqAstropaycardUiDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqApcoDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqApcoDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PwclickPwclickDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PwclickPwclickDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankNbDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankNbDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankUiDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankUiDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankBlDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankBlDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankIxDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankIxDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankBtvoucherAtmDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankBtvoucherAtmDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankJcDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankJcDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankBtvoucherDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankBtvoucherDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankPpbtvoucherDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankPpbtvoucherDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankBriteDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankBriteDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankInovapayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankInovapayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankNetBankingDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankNetBankingDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankRedeemVoucherDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankRedeemVoucherDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankPixDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankPixDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankDirecta24Deposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankDirecta24Deposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankUpiDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankUpiDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankVoltDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankVoltDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankVoltWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankVoltWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankWalletDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankWalletDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqAstropaybankWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqAstropaybankWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqAstropaybankPixWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqAstropaybankPixWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqAstropaybankD24indiaWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqAstropaybankD24indiaWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqInovapaybankWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqInovapaybankWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqInovapaybankPixWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqInovapaybankPixWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqInovapaywalletDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqInovapaywalletDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqInovapaywalletWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqInovapaywalletWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqGate2waywalletPaytmWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqGate2waywalletPaytmWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankInteracDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankInteracDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankNetbankingDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankNetbankingDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankdomesticWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankdomesticWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankibanDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankibanDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankibanOnlineueberweisenDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankibanOnlineueberweisenDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankibanWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankibanWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankibanBriteWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankibanBriteWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankibanEbpWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankibanEbpWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankibanNodapayWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankibanNodapayWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankintlWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankintlWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBestpaytransferWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBestpaytransferWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankBriteWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankBriteWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankintlInpayWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankintlInpayWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankintlNzdWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankintlNzdWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankintlNetbankingWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankintlNetbankingWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqPaymerobankWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqPaymerobankWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBanklocalWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBanklocalWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBanklocalInterkassaWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBanklocalInterkassaWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBanklocalInpayjpWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBanklocalInpayjpWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBanklocalUpiWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBanklocalUpiWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBanklocalBtbWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBanklocalBtbWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBanklocalLocalBankTransferWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBanklocalLocalBankTransferWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBanklocalSumopayWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBanklocalSumopayWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBanklocalPagavaWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBanklocalPagavaWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBanklocalUpiWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBanklocalUpiWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBanklocalImpsWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBanklocalImpsWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBanklocalVipwithdrawalJpWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBanklocalVipwithdrawalJpWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqBankParamountDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqBankParamountDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqCashlibDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqCashlibDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqCreditcardDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqCreditcardDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqCreditcardMastercardDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqCreditcardMastercardDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqCreditcardRupayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqCreditcardRupayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqCreditcardVisaDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqCreditcardVisaDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqCreditcardWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqCreditcardWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqCryptocurrencyDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqCryptocurrencyDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqCryptocurrencyBitpaceDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqCryptocurrencyBitpaceDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqCryptocurrencyCryptopayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqCryptocurrencyCryptopayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqCryptocurrencyWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqCryptocurrencyWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqCryptocurrencyBitpaceWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqCryptocurrencyBitpaceWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqCryptocurrencyCryptopayWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqCryptocurrencyCryptopayWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqEcobanqDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqEcobanqDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqEcobanqWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqEcobanqWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqEcopayzDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqEcopayzDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqEcopayzWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqEcopayzWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqEnvoyDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqEnvoyDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqEnvoyWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqEnvoyWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqEutellerDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqEutellerDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqIdealDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqIdealDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqInstadebitDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqInstadebitDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqInstadebitWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqInstadebitWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqIDebitDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqIDebitDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqIDebitWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqIDebitWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqParamountWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqParamountWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqInteracWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqInteracWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqInterkassawalletUpiDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqInterkassawalletUpiDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqInterkassawalletUpiWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqInterkassawalletUpiWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqInterkassawalletWalletDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqInterkassawalletWalletDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqIwalletDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqIwalletDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqIwalletWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqIwalletWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqKluwpDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqKluwpDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqLuxonpayWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqLuxonpayWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqMuchbetterDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqMuchbetterDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqMuchbetterWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqMuchbetterWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqNetellerDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqNetellerDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqNetellerWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqNetellerWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqPay4funWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqPay4funWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqPproDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqPproDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqPproIdealDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqPproIdealDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqPugglepayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqPugglepayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqPugglepayWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqPugglepayWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqPayretailersWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqPayretailersWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqPayretailersPixWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqPayretailersPixWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqSiirtoDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqSiirtoDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqSiirtoWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqSiirtoWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqSkrillDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqSkrillDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqSkrillqcoDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqSkrillqcoDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqSkrillqcoWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqSkrillqcoWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqSkrillWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqSkrillWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqSofortDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqSofortDeposit'},
    },

    {
      if: {
        properties: {
          provider: {
            const: 'PiqMifinityDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqMifinityDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqMifinityUnionpayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqMifinityUnionpayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqMifinityKlarnaDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqMifinityKlarnaDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqMifinityWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqMifinityWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqSticpayWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqSticpayWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqSwishDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqSwishDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqTrustlyDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqTrustlyDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqTrustlyWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqTrustlyWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqVenuspointDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqVenuspointDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqVegaDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqVegaDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqVenuspointWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqVenuspointWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqVegaWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqVegaWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectSparkasseDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectSparkasseDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectVolksbankenDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectVolksbankenDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectDeutschebankDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectDeutschebankDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectPostbankDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectPostbankDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectCommerzbankDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectCommerzbankDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectDeutschekreditbankDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectDeutschekreditbankDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectOpDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectOpDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectApplepayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectApplepayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectGooglepayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectGooglepayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectColibrixGooglepayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectColibrixGooglepayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectColibrixApplepayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectColibrixApplepayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectOnlineBankingDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectOnlineBankingDeposit'},
    },

    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectEbpDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectEbpDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectTrustlyDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectTrustlyDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectMacropayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectMacropayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectSofortuberweisungDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectSofortuberweisungDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectNodapayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectNodapayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectBoletoDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectBoletoDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectBoletoPixDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectBoletoPixDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectBtDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectBtDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqEzeebillBtjpnWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqEzeebillBtjpnWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectSofortDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectSofortDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectOnlineDebitDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectOnlineDebitDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectDirectPaymentDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectDirectPaymentDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectPixOnlineDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectPixOnlineDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectUpiH5Deposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectUpiH5Deposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectPaytmWalletDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectPaytmWalletDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectPhonepeWalletDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectPhonepeWalletDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectMobikwikWalletDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectMobikwikWalletDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectAirtelWalletDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectAirtelWalletDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectJioWalletDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectJioWalletDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectCreditCardDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectCreditCardDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectTigerpayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectTigerpayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectBtbDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectBtbDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectBtbAutoDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectBtbAutoDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectTigerpayWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectTigerpayWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectUpiWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectUpiWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectLocalBankTransferWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectLocalBankTransferWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectSumopayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectSumopayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectQuickbitDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectQuickbitDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectJpayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectJpayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectIdealDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectIdealDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectIdealBetapinDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectIdealBetapinDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectPaypalBetapinDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectPaypalBetapinDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectPaypalDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectPaypalDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectZotapayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectZotapayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectFlykkDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectFlykkDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectFinsupportDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectFinsupportDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqCryptocurrencyFinsupportDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqCryptocurrencyFinsupportDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectOnrampDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectOnrampDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectIbDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectIbDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectPneDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectPneDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectMandatoDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectMandatoDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectMandatoWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectMandatoWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectPagavaDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectPagavaDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectBdbanksDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectBdbanksDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectVisaDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectVisaDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectMastercardDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectMastercardDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectRupayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectRupayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectUpiDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectUpiDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectSticpayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectSticpayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectQaicashDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectQaicashDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectZotacardDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectZotacardDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectPaytmDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectPaytmDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectImpsDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectImpsDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectGpayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectGpayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectPhonepeDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectPhonepeDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectQaicashWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectQaicashWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectPointinoutDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectPointinoutDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectQpointsDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectQpointsDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectPointinoutWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectPointinoutWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectQpointsWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectQpointsWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectOnrampWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectOnrampWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqZimplerDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqZimplerDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqZimplerWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqZimplerWithdrawal'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'ProjsBankidAuth',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/ProjsBankidAuth'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'ProjsSwishDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/ProjsSwishDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectGatowayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectGatowayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectGate2wayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectGate2wayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectGate2wayskrillDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectGate2wayskrillDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectGate2waynetellerDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectGate2waynetellerDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectGate2waysofortDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectGate2waysofortDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectGate2waywebpayzDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectGate2waywebpayzDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectGate2waygiropayDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectGate2waygiropayDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectGate2waypaysafecardDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectGate2waypaysafecardDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'PiqWebredirectGate2waypaysafecashDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/PiqWebredirectGate2waypaysafecashDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'TransferworldTransferworldDeposit',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/TransferworldTransferworldDeposit'},
    },
    {
      if: {
        properties: {
          provider: {
            const: 'TransferworldTransferworldWithdrawal',
          },
        },
        required: ['provider'],
      },
      then: {$ref: '#/definitions/TransferworldTransferworldWithdrawal'},
    },
  ],
}
